import React from 'react'
import styled from 'styled-components';
import logoImage from './logo.png';

const PageContainer = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px; /* Add padding for small screens */
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Logo = styled.img`
  height: 60px;
  width: 240px;
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CenterText = styled.h1`
  font-size: 4rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 80px;
  
  background: black;
  -webkit-background-clip: text; /* Clip the gradient to the text */
  -webkit-text-fill-color: transparent; /* Make the text color transparent */

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 50px;
  }
`;

const SubCenterText = styled.h3`
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background: black;
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-background-clip: text; /* Clip the gradient to the text */
  -webkit-text-fill-color: transparent; /* Make the text color transparent */

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 30px;
  }
`;

// const ActionButton = styled.button`
//   color: #fff;
//   background-color: #000;
//   border: 2px solid black;
//   border-radius: 10px;
//   padding: 9px 19px;
//   font-size: 0.85rem;
//   font-weight: bold;
//   cursor: pointer;
//   font-family: 'Inter', sans-serif;
//   font-weight: 500;
//   // &:hover {
//   //   color: #000;
//   //   background-color: #fff;
//   //   border-color: white;
//   // }

//   @media (max-width: 768px) {
//     padding: 5px 10px;
//     font-size: 0.8rem;
//   }
// `;

const Home = () => {

  return (
    <PageContainer>
      <HeaderContainer>
        <Header>
          <Logo src={logoImage} alt="GourmetChef Logo" />
        </Header>
      </HeaderContainer>
      <CenterText>Hi, I'm Nimai Garg</CenterText>
      <SubCenterText>A 16 year old developer based out of Cupertino, California. Website coming out soon!</SubCenterText>
    </PageContainer>
  )
}

export default Home
